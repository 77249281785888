export const VALID_NODE_NAMES = [
  `link`,
  `meta`,
  `style`,
  `title`,
  `base`,
  `noscript`,
  `script`,
  `html`,
  `body`,
]
